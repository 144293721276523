import Logo from "../components/logo";
import useIsMobile from "../hooks/useIsMobile";

const TwoColContent = ({
  leftContent,
  rightContent,
  logoColor = "#450b70",
}) => {
  const isMobile = useIsMobile();

  // Mobile layout
  if (isMobile) {
    return (
      <div className="flex flex-col w-full min-h-screen bg-white">
        <div className="bg-float-purple-6 p-6">
          <Logo color={logoColor} />
        </div>

        <div className="fixed bottom-0 left-0 w-full bg-float-purple-6 shadow-lg z-10">
          <div className="flex justify-around py-4">{leftContent}</div>
        </div>

        <div className="flex-1 p-4 pb-24">{rightContent}</div>
      </div>
    );
  }

  // Desktop layout (unchanged)
  return (
    <div className="flex sm:flex-row w-full min-h-screen max-h-full">
      <div className="flex flex-1 justify-between flex-col bg-float-purple-6 pt-14 px-6 md:px-10 lg:px-14 pb-18 max-w-md">
        <div className="h-64">
          <Logo color={logoColor} />
        </div>
        {leftContent}
      </div>
      <div className="flex-1">{rightContent}</div>
    </div>
  );
};

export default TwoColContent;
