import { Redirect } from "react-router-dom";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import Input from "../../components/input";
import { resetPassword } from "../../api";
import { SecurePatternLayout } from "../../templates/SecurePatternLayout";
import { Button } from "../../components";

const ResetPassword = ({ location }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [done, setDone] = useState(false);
  const search = new URLSearchParams(location.search);
  let token = search.get("token");

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    onSubmit: async (values) => {
      setError("");
      setLoading(true);
      try {
        formik.setSubmitting(false);
        await resetPassword({ password: values.password, token });
        setDone(true);
        formik.resetForm();
      } catch (err) {
        setError(err.response.data.password[0]);
      } finally {
        setLoading(false);
        setDone(true);
      }
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Password is required")
        .min(8, "Password is too short - should be 8 charactes minimum."),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
      ),
    }),
  });

  if (done) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: {
            changedPassword: true,
          },
        }}
      />
    );
  }

  if (!token) {
    return <Redirect to="/login" />;
  }

  return (
    <SecurePatternLayout>
      <div className="h-fit box-shadow bg-white rounded-2xl p-6 sm:p-12 flex flex-col w-full sm:w-[480px] z-10 max-w-[95%]">
        <h2 className="text-xl sm:text-2xl text-float-purple-1">
          Reset your password
        </h2>
        <div className="mt-3 sm:mt-4 text-sm sm:text-base text-float-grey-90">
          <p>Password must contain:</p>
          <ul className="list-disc list-inside">
            <li>Minimum of 8 characters</li>
            <li>At least one special character</li>
          </ul>
        </div>
        <form
          className="mt-4 sm:mt-6 flex flex-col gap-2 sm:gap-3"
          onSubmit={formik.handleSubmit}
          onReset={formik.handleReset}
        >
          <Input
            label="New password"
            placeholder=""
            type="password"
            id="password"
            value={formik.values.password}
            error={formik.errors.password}
            onChange={formik.handleChange}
          />
          <span className="text-float-grey-70 text-xs -mt-1 sm:-mt-2">
            Can't be a previously used password
          </span>
          <Input
            label="Confirm password"
            placeholder=""
            type="password"
            id="confirmPassword"
            value={formik.values.confirmPassword}
            error={formik.errors.confirmPassword || error}
            onChange={formik.handleChange}
          />
          <Button
            type="submit"
            className="w-full mt-6 sm:mt-10 py-2 px-4 bg-float-purple-2 hover:bg-float-purple-3 text-white rounded-md transition-colors focus:outline-none focus:ring-2 focus:ring-float-purple-4 focus:ring-offset-2"
            disabled={loading}
          >
            Reset password
          </Button>
        </form>
      </div>
    </SecurePatternLayout>
  );
};

export default ResetPassword;
