export * from "./HowItWorks";
export * from "./SideBar";
export * from "./SignUpBankID";
export * from "./SignUpEmail";
export * from "./SignUpGoogleSSO";
export * from "./LoginEmail";
export * from "./AccountCreated";

// Mobile-friendly form styling utilities
export const getMobileFormStyles = (isMobile) => {
  if (!isMobile) return {}; // Return empty object for desktop to use existing styles

  return {
    container: "px-4 py-2",
    title: "text-xl font-bold mb-4",
    subtitle: "text-sm mb-6 text-gray-600",
    form: "flex flex-col gap-4",
    formGroup: "mb-4",
    label: "block text-sm font-medium mb-1",
    input:
      "w-full h-10 px-3 border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-float-purple-3",
    error: "text-red-600 text-xs mt-1",
    buttonGroup: "flex flex-col gap-3 mt-6",
    primaryButton:
      "bg-float-purple-2 text-white font-medium py-3 px-4 rounded-lg w-full",
    secondaryButton:
      "border border-float-purple-2 text-float-purple-2 font-medium py-3 px-4 rounded-lg w-full",
    backLink: "text-center text-float-purple-2 mt-4 font-medium",
  };
};

// Helper components for mobile form styling
export const MobileFormHeader = ({ title, subtitle, isMobile }) => {
  if (!isMobile) return null;

  return (
    <div className="mb-6">
      <h1 className="text-xl font-bold mb-2">{title}</h1>
      {subtitle && <p className="text-sm text-gray-600">{subtitle}</p>}
    </div>
  );
};

export const MobileFormButtons = ({
  onBack,
  onNext,
  nextLabel = "Continue",
  backLabel = "Back",
  isMobile,
}) => {
  if (!isMobile) return null;

  return (
    <div className="flex flex-col gap-3 mt-6">
      <button
        type="submit"
        className="bg-float-purple-2 text-white font-medium py-3 px-4 rounded-lg w-full"
      >
        {nextLabel}
      </button>

      {onBack && (
        <button
          type="button"
          onClick={onBack}
          className="border border-float-purple-2 text-float-purple-2 font-medium py-3 px-4 rounded-lg w-full"
        >
          {backLabel}
        </button>
      )}
    </div>
  );
};
