import { InfoModal } from "../../../../components";
import { useState, useEffect } from "react";
import { patchUserRole } from "../../../../api";
import { ToastType } from "../../../../types";
import { Toast } from "../../../../components/toast";

export const ManageUserModal = ({
  user,
  visible,
  hide,
  onDelete,
  refetchUsers,
  isMobile,
}) => {
  const [tab, setTab] = useState("change_role");
  const [isModalMobile, setIsModalMobile] = useState(false);

  useEffect(() => {
    // Set modal mobile state based on prop or detect screen size
    if (isMobile !== undefined) {
      setIsModalMobile(isMobile);
    } else {
      // Fallback detection if prop not passed
      const checkIsMobile = () => {
        setIsModalMobile(window.innerWidth < 768);
      };

      checkIsMobile();
      window.addEventListener("resize", checkIsMobile);

      return () => window.removeEventListener("resize", checkIsMobile);
    }
  }, [isMobile]);

  const roles = [
    {
      label: "Admin",
      value: "admin",
      description: "Full access to user management",
    },
    {
      label: "User",
      value: "user",
      description: "Limited access to user management",
    },
  ];
  const [role, setRole] = useState(user.role?.role);
  const handleRoleChange = (role, id) => {
    try {
      patchUserRole(id, role);
      refetchUsers();
      Toast({
        type: ToastType.SUCCESS,
        title: "Success",
        text: "Role updated successfully",
      });
    } catch (e) {
      console.log(e);
    }
  };
  const onHandleSubmit = () => {
    if (tab === "change_role") {
      handleRoleChange(role, user.role.id);
    } else {
      onDelete();
    }
  };
  return (
    <InfoModal
      hide={hide}
      visible={visible}
      title="Manage User"
      content={
        <p className={`mb-6 ${isModalMobile ? "w-full px-2" : "w-[480px]"}`}>
          <div className="border-b w-full mb-4">
            <div className="flex gap-4 ml-2">
              <div
                className={`settings-active pb-2 cursor-pointer ${
                  tab === "change_role" ? "active" : ""
                }`}
                onClick={() => {
                  setTab("change_role");
                }}
              >
                <p
                  className={`${
                    tab === "change_role" ? "" : "text-float-grey-90"
                  } ${isModalMobile ? "text-sm" : ""}`}
                >
                  Change role
                </p>
              </div>
              <div
                className={`settings-active pb-2 cursor-pointer ${
                  tab === "delete_user" ? "active" : ""
                }`}
                onClick={() => {
                  setTab("delete_user");
                }}
              >
                <p
                  className={`${
                    tab === "delete_user" ? "" : "text-float-grey-90"
                  } ${isModalMobile ? "text-sm" : ""}`}
                >
                  Delete user
                </p>
              </div>
            </div>
          </div>
          {tab === "delete_user" && (
            <p className={isModalMobile ? "text-sm" : ""}>
              {`This user will not be able to log in to your company account anymore. Are you sure you want to delete ${user.first_name} ${user.last_name}?`}
            </p>
          )}
          {tab === "change_role" && (
            <>
              <p className={`mb-4 ${isModalMobile ? "text-sm" : ""}`}>
                {`Select a new role for ${user.first_name} ${user.last_name}`}
              </p>
              <div className="flex flex-col gap-4">
                {roles.map((role) => (
                  <div
                    key={role.value}
                    className={`border p-4 rounded-md flex ${
                      isModalMobile ? "p-3" : ""
                    }`}
                  >
                    <input
                      id={role.value}
                      type="radio"
                      name="role"
                      value={role.value}
                      onChange={() => setRole(role.value)}
                      className={`my-auto ${isModalMobile ? "w-5 h-5" : ""}`}
                    />
                    <div className="flex flex-col ml-4" key={role.value}>
                      <p className={isModalMobile ? "text-sm" : ""}>
                        {role.label}
                      </p>
                      <p
                        className={`text-float-grey-90 ${
                          isModalMobile ? "text-xs" : ""
                        }`}
                      >
                        {role.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </p>
      }
      closeText="No, cancel"
      submitButton={
        tab === "change_role" ? (
          <button
            className={isModalMobile ? "text-sm py-2 px-3" : ""}
            onClick={onHandleSubmit}
          >
            Save changes
          </button>
        ) : (
          <button
            className={`w-fit whitespace-nowrap cursor-pointer bg-float-red-1 border-float-red-1 hover:bg-white hover:text-float-red-1 transition-colors duration-200 hover:border-float-red-1 ${
              isModalMobile ? "text-xs py-2 px-3" : "text-xs"
            }`}
            onClick={onHandleSubmit}
          >
            Delete user
          </button>
        )
      }
    />
  );
};
