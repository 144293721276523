import { useGoogleLogin } from "@react-oauth/google";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import GoogleLogo from "../../components/logoGoogle";
import { googleLogin } from "../../api/auth";
import { Toast } from "../../components/toast";
import { ToastType } from "../../types";
import { Button } from "../../components";
import { ShowCompanyModalContext } from "../../hooks/useShowCompanyModal";
import { LoginRedirect } from "./loginRedirect";
import { useSuccessLogin } from "../../hooks/useSuccessLogin";
import { UserContext } from "../../hooks/useWhoAmI";

const GoogleSSOLogin = ({ invite_token }) => {
  const [done, setDone] = useState(false);
  const history = useHistory();
  const { setShowCompanyModal } = useContext(ShowCompanyModalContext);
  const { setCookiesAndLogin } = useSuccessLogin();
  const { login } = useContext(UserContext);

  const loginGoogle = useGoogleLogin({
    onSuccess: async (googleData) => {
      try {
        const token = await googleLogin({ invite_token, ...googleData });
        await login(token);
        setDone(true);
      } catch (err) {
        console.error(err);
        Toast({
          type: ToastType.ERROR,
          title: "Failed to login!",
          text: "Authentication with google failed.",
        });
      }
    },
    onError: (response) => console.error(response),
  });

  useEffect(() => {
    if (done) {
      LoginRedirect(history, setCookiesAndLogin, setShowCompanyModal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [done, history]);

  return (
    <>
      <div className="w-full flex items-center mt-4 sm:mt-6">
        <span className="h-px bg-float-grey-10 flex-1" />
        <p className="text-float-grey-90 text-xs sm:text-sm mx-2">OR</p>
        <span className="h-px bg-float-grey-10 flex-1" />
      </div>
      <Button
        onClick={() => loginGoogle()}
        className="w-full mt-5 sm:mt-8 py-2 px-4 border border-gray-300 shadow-sm"
      >
        <div className="flex flex-row items-center justify-center">
          <GoogleLogo width={30} height={30} className="mr-2" />
          <p className="text-sm sm:text-base">Continue with Google</p>
        </div>
      </Button>
    </>
  );
};

export default GoogleSSOLogin;
