import TwoColContent from "../../../templates/twoColContent";
import { SideBar } from "../components";
import { useVerifyEmail } from "./hooks";
import Loading from "../../../components/loading";
import { Redirect } from "react-router-dom";
import { clearToken } from "../../../common/localstorage";
import useIsMobile from "../../../hooks/useIsMobile";

const ExhangeCodeManual = () => {
  const { loading, email, done } = useVerifyEmail();
  const isMobile = useIsMobile();

  clearToken();
  // Loading state
  if (loading) {
    return (
      <TwoColContent
        leftContent={<SideBar />}
        rightContent={
          <div
            className={`flex h-full items-center justify-center ${
              isMobile ? "p-4" : ""
            }`}
          >
            {isMobile && (
              <div className="text-center mb-4">Verifying your email...</div>
            )}
            <Loading />
          </div>
        }
      />
    );
  }

  // Success state - redirect to manual signup
  if (done && email) {
    return (
      <Redirect
        to={{
          pathname: "/signup/manual",
          state: { email },
        }}
      />
    );
  }

  // Error state
  return (
    <TwoColContent
      leftContent={<SideBar />}
      rightContent={
        <div
          className={`flex flex-col h-full items-center justify-center ${
            isMobile ? "p-6 text-center" : ""
          }`}
        >
          <div
            className={`${
              isMobile ? "bg-white p-6 rounded-lg shadow-md w-full" : ""
            }`}
          >
            <h3 className={`${isMobile ? "text-xl mb-4" : ""}`}>
              Oops, something went wrong
            </h3>
            {isMobile && (
              <p className="text-gray-600 mb-6">
                We couldn't verify your email. Please try clicking the link
                again or request a new verification email.
              </p>
            )}
            <button
              onClick={() => (window.location.href = "/signup")}
              className={`${
                isMobile
                  ? "bg-float-purple-2 text-white font-medium py-3 px-4 rounded-lg w-full"
                  : ""
              }`}
            >
              Back to signup
            </button>
          </div>
        </div>
      }
    />
  );
};

export default ExhangeCodeManual;
