export const BankItem = ({ logo, name, onClick, selected }) => {
  return (
    <div
      className={`cursor-pointer flex flex-row w-[calc(100%-2rem)] md:w-2/5 mb-2 border rounded-md p-2 mx-4 md:ml-8 md:mr-0 ${
        selected ? "border-float-purple-4" : "border-float-grey-10"
      }`}
      onClick={onClick}
    >
      <img
        alt={`logo for ${name}`}
        src={logo}
        className="w-8 h-8 md:w-10 md:h-10 my-auto object-contain"
      />
      <p className="my-auto ml-3 text-sm md:text-base flex-1">{name}</p>
    </div>
  );
};
