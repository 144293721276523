import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { Redirect } from "react-router-dom";
import Input from "../../components/input";
import { forgotPassword } from "../../api";
import { Link } from "react-router-dom";
import { SecurePatternLayout } from "../../templates/SecurePatternLayout";
import { Button } from "../../components";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [done, setDone] = useState(false);

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      email: "",
    },
    onSubmit: async (values) => {
      setEmail("");
      setLoading(true);
      try {
        formik.setSubmitting(false);
        await forgotPassword(values);
        setDone(true);
        setEmail(values.email);
      } finally {
        setLoading(false);
        formik.resetForm();
        setDone(false);
      }
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Must be a valid e-mail")
        .required("Email is required"),
    }),
  });

  if (done) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: {
            resetPassword: email,
          },
        }}
      />
    );
  }

  return (
    <SecurePatternLayout>
      <div className="h-fit box-shadow bg-white rounded-2xl p-6 sm:p-12 flex flex-col w-full sm:w-[480px] z-10 max-w-[95%]">
        <h2 className="text-xl sm:text-2xl text-float-purple-1">
          Forgot your password?
        </h2>
        <p className="mt-3 sm:mt-4 text-sm sm:text-base text-float-grey-90">
          Having trouble signing in? Just enter your email and we'll send you a
          link to reset your password.
        </p>
        <form
          onSubmit={formik.handleSubmit}
          onReset={formik.handleReset}
          className="mt-4 sm:mt-6"
        >
          <Input
            label="Your email"
            type="text"
            id="email"
            placeholder="E-mail"
            value={formik.values.email}
            error={formik.errors.email}
            onChange={formik.handleChange}
          />
          <Button
            type="submit"
            className="w-full mt-6 sm:mt-10 py-2 px-4 bg-float-purple-2 hover:bg-float-purple-3 text-white rounded-md transition-colors focus:outline-none focus:ring-2 focus:ring-float-purple-4 focus:ring-offset-2"
            disabled={loading}
          >
            Send me a reset link
          </Button>
        </form>
        <div className="mt-5 sm:mt-7 text-center">
          <Link
            to={"/login"}
            className="font-bold text-sm sm:text-base text-float-purple-2"
          >
            Back to login page
          </Link>
        </div>
      </div>
    </SecurePatternLayout>
  );
};

export default ForgotPassword;
