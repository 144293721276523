import { InfoModal } from "../infoModal";
import Input from "../input";
import { useInviteUserForm } from "../../hooks/useInviteUserForm";
import { Dropdown } from "../dropdown";
import { useEffect, useState } from "react";

export const AddUserModal = ({
  hide,
  visible,
  setOpenAddUserModal,
  fetchInvites,
  isMobile,
}) => {
  const { formik, submitErrors, isLoading } = useInviteUserForm({
    setOpenAddUserModal,
    fetchInvites,
  });
  const [isModalMobile, setIsModalMobile] = useState(false);

  useEffect(() => {
    // Set modal mobile state based on prop or detect screen size
    if (isMobile !== undefined) {
      setIsModalMobile(isMobile);
    } else {
      // Fallback detection if prop not passed
      const checkIsMobile = () => {
        setIsModalMobile(window.innerWidth < 768);
      };

      checkIsMobile();
      window.addEventListener("resize", checkIsMobile);

      return () => window.removeEventListener("resize", checkIsMobile);
    }
  }, [isMobile]);

  const roles = [
    { label: "Admin", value: "admin" },
    { label: "User", value: "user" },
  ];

  return (
    <InfoModal
      hide={hide}
      visible={visible}
      title="Invite a user"
      closeText="Cancel"
      submitButton={
        <button
          disabled={isLoading}
          form="addUserForm"
          type="submit"
          onClick={formik.handleSubmit}
          className={isModalMobile ? "text-sm py-2 px-3" : ""}
        >
          Invite user
        </button>
      }
      content={
        <form
          className={`${
            isModalMobile ? "w-full" : "w-[300px] md:w-[514px]"
          } flex flex-col gap-4 mb-8`}
          id="addUserForm"
        >
          <Input
            id="email"
            label="Email - company address"
            placeholder="Fill in email"
            type="text"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.errors.email}
            className={isModalMobile ? "text-sm" : ""}
          />
          <Dropdown
            id="role"
            label="Role"
            type="select"
            value={formik.values.role}
            onChange={formik.handleChange}
            error={formik.errors.role}
            options={roles}
            placeholder="Choose role"
            className={isModalMobile ? "text-sm" : ""}
          />
          {submitErrors &&
            submitErrors.map((error) => (
              <p
                key={error}
                className={`text-xs text-red-600 ${
                  isModalMobile ? "text-xs" : ""
                }`}
              >
                {error}
              </p>
            ))}
        </form>
      }
    />
  );
};
