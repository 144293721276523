import { Avatar } from "../../../../components";
import { inviteNotify } from "../../../../api";
import { ToastType } from "../../../../types";
import { Toast } from "../../../../components/toast";

export const InviteRow = ({ invite, isMobile }) => {
  const handleReinvite = (id) => {
    try {
      inviteNotify(id);
      Toast({
        type: ToastType.SUCCESS,
        title: "Success",
        text: "Invitation successfully resent!",
      });
    } catch (e) {
      Toast({
        type: ToastType.ERROR,
        title: "Oh no, there was an error",
        text: e.message,
      });
    }
  };

  if (isMobile) {
    return (
      <div className="border rounded-lg p-3 mb-2 shadow-sm">
        <div className="flex items-center gap-2">
          <Avatar size={14} />
          <div className="overflow-hidden flex-1">
            <p className="text-xs break-all">{invite.email}</p>
          </div>
        </div>

        <div className="flex justify-between items-center mt-2 pt-2 border-t">
          <div>
            <div className="flex items-center">
              <p className="text-xs font-medium text-float-grey-90 mr-1">
                Role:
              </p>
              <p className="text-xs">{invite.role}</p>
            </div>
            <div className="flex items-center mt-1">
              <p className="text-xs font-medium text-float-grey-90 mr-1">
                Status:
              </p>
              <p className="text-xs">{invite.status}</p>
            </div>
          </div>

          <button
            className="text-xs py-1 px-2 whitespace-nowrap cursor-pointer"
            onClick={() => {
              handleReinvite(invite.id);
            }}
          >
            Resend
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="border-t grid grid-cols-[minmax(220px,_1fr)_minmax(250px,_1fr)_minmax(150px,_0.3fr)_minmax(80px,_1fr)] py-4">
      <div className="flex items-center gap-4">
        <Avatar size={16} />
        <p className="text-sm">{invite.email}</p>
      </div>
      <div className="flex items-center">
        <p className="text-sm">{invite.role}</p>
      </div>
      <div className="flex items-center">
        <p className="text-sm">{invite.status}</p>
      </div>
      <div className="flex items-center justify-end">
        <button
          className="w-fit text-xs whitespace-nowrap cursor-pointer"
          onClick={() => {
            handleReinvite(invite.id);
          }}
        >
          Resend invitation
        </button>
      </div>
    </div>
  );
};
