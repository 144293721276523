export const UploadAccountingInformation = ({ country }) => {
  return (
    <div className="flex flex-col w-full">
      <h5 className="text-base font-semibold">P&L (income statement)</h5>
      <ul className="list-disc list-outside font-medium text-gray-700 ml-5">
        <li>
          Monthly income statements of the past 18+ months (preferably 24+
          months)
        </li>
      </ul>
      <h5 className="text-base font-semibold mt-5">Balance sheet</h5>
      <ul className="list-disc list-outside font-medium text-gray-700 ml-5">
        <li>The latest full-year balance sheet (e.g. FY 2022)</li>
        <li>The latest monthly balance sheet</li>
      </ul>
      {country === "de" && (
        <>
          <h5 className="text-base font-semibold mt-5">GDPdU</h5>
          <p className="font-medium text-gray-700">
            Please also provide the GDPdU data export from your accounting
            software.
          </p>
          <a
            href="https://docs.google.com/document/d/1kr8pEKUprHoBZP-dfkU4_7YKB-_6QFYAECTopqI2r_w/edit?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
            className="text-float-purple-2 hover:text-float-purple-1 mt-2 inline-block"
          >
            See GDPdU documentation
          </a>
        </>
      )}
      <h5 className="text-base font-semibold mt-5">
        Is your company part of a group?
      </h5>
      <p className="font-medium text-gray-700">We require the above data as</p>
      <ul className="list-disc list-outside font-medium text-gray-700 ml-5">
        <li>consolidated group financials (preferred), or</li>
        <li>
          the individual financials for each affiliated legal entity (if
          consolidated is not available)
        </li>
      </ul>
    </div>
  );
};
