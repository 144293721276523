import { CountryItem } from "../countryItem";
import { getCountries } from "../../../../api";
import { useEffect, useState } from "react";

export const CountryList = ({ setCountry, country }) => {
  const [countries, setCountries] = useState([]);
  const [countriesOriginal, setCountriesOriginal] = useState([]);
  useEffect(() => {
    const fetchCountries = async () => {
      // TODO: Error handling
      const countries = await getCountries();
      const filteredCountries = countries.filter((c) =>
        Boolean(c.ais_go_cardless)
      );
      const sortedCountries = filteredCountries.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setCountries(sortedCountries);
      setCountriesOriginal(sortedCountries);
    };
    fetchCountries();
  }, []);

  return (
    <div className="flex flex-col h-full overflow-auto max-h-[500px]">
      <div className="w-full px-4 md:px-8 py-4 sticky top-0 bg-white z-10 border-b md:border-none">
        <input
          type="text"
          placeholder="Search"
          className="border rounded-lg w-full h-10 md:h-9 px-3 focus:outline-none focus:ring-1 focus:ring-float-purple-4"
          onChange={(e) => {
            const value = e.target.value;
            if (!value) {
              setCountries(countriesOriginal);
              return;
            }
            const filteredCountries = countries.filter((c) =>
              c.name.toLowerCase().includes(value.toLowerCase())
            );
            setCountries(filteredCountries);
          }}
        />
      </div>
      <div className="flex flex-wrap pt-4">
        {countries?.map((c) => (
          <CountryItem
            key={c.id}
            name={c.name}
            code={c.code}
            onClick={() => {
              setCountry(c);
            }}
          />
        ))}
      </div>
    </div>
  );
};
