import React from "react";
import { Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Router, Switch, Route } from "react-router-dom";
import AccountCreated from "../views/signup/components/AccountCreated";
import Admin from "../views/admin";
import AdminRoute from "./AdminRoute";
import AutomatedSignUp from "../views/signup/automated/AutomatedSignUp";
import { CheckYourEmail } from "../views/signup/manual/components";
import CompanyDetails from "../views/settings/companyDetails";
import CompanyExists from "../views/signup/manual/companyExists";
import Dashboard from "../views/dashboard";
import ExhangeCodeAutomated from "../views/signup/automated/ExhangeCodeAutomated";
import ExhangeCodeManual from "../views/signup/manual/ExchangeCodeManual";
import FAQ from "../views/faq";
import ForgotPassword from "../views/login/ForgotPassword";
import Loan from "../views/loan";
import LoginPage from "../views/login";
import NoCompany from "../views/login/noCompany";
import { ManualSignUp } from "../views/signup/manual/ManualSignUp";
import PrivateRoute from "./PrivateRoute";
import ProcessLoginPage from "../views/login/process";
import ResetPassword from "../views/login/ResetPassword";
import SetPassword from "../views/login/setPassword";
import { SuccessPage } from "../views/dashboard/onboard/components";
import Connect from "../views/connect";
import Users from "../views/users";
import SignUp from "../views/signup";
import YourDetails from "../views/settings/yourDetails";
import SignUpRoute from "./SignUpRoute";
import AcceptInvite from "../views/login/AcceptInvite";
import AcceptEidInvitePage from "../views/login/AcceptEidInvite";

export const history = createBrowserHistory();

const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route
          path="/signup/manual/company-exists"
          component={CompanyExists}
          exact={true}
        />
        <Route
          path="/login/set-password/"
          component={SetPassword}
          exact={true}
        />
        <Route path="/login/invite/" component={AcceptInvite} exact={true} />
        <Route
          path="/login/invite/eid/"
          component={AcceptEidInvitePage}
          exact={true}
        />
        <Route path="/login" component={LoginPage} />
        <Route path="/signup/no-company" component={NoCompany} exact={true} />
        <Route path="/signup/manual" component={ManualSignUp} exact={true} />
        <Route
          path="/signup/manual/exchange-code"
          component={ExhangeCodeManual}
          exact={true}
        />
        <Route
          path="/signup/account-created"
          component={AccountCreated}
          exact={true}
        />
        <Route
          path="/signup/automated/exchange-code"
          component={ExhangeCodeAutomated}
          exact={true}
        />
        <Route path="/signup" component={SignUp} exact={true} />
        <Route path="/email-verify" component={CheckYourEmail} />
        <Route path="/process/login" component={ProcessLoginPage} />
        <Route path="/reset-password" component={ForgotPassword} />
        <Route path="/forgot-password" component={ResetPassword} />

        <SignUpRoute path="/signup/automated" exact={true}>
          <AutomatedSignUp />
        </SignUpRoute>

        <PrivateRoute path="/connect" exact={true}>
          <Connect />
        </PrivateRoute>

        <PrivateRoute path="/dashboard/success" exact={true}>
          <SuccessPage />
        </PrivateRoute>
        <PrivateRoute path="/dashboard">
          <Dashboard />
        </PrivateRoute>
        <PrivateRoute path="/faq">
          <FAQ />
        </PrivateRoute>
        <PrivateRoute path="/users">
          <Users />
        </PrivateRoute>
        <PrivateRoute path="/loan">
          <Loan />
        </PrivateRoute>
        <PrivateRoute path="/settings/company-details">
          <CompanyDetails />
        </PrivateRoute>
        <PrivateRoute path="/settings/my-details">
          <YourDetails />
        </PrivateRoute>
        <PrivateRoute path="/settings">
          <Redirect to="/settings/my-details" />
        </PrivateRoute>
        <AdminRoute path="/admin">
          <Admin />
        </AdminRoute>
        <Redirect from="/" to="/dashboard" />
      </Switch>
    </Router>
  );
};

export default Routes;
