import LogoSmall from "../../components/logoSmall";
import { UserContext } from "../../hooks/useWhoAmI";
import { Icon } from "@iconify/react";
import Helmet from "react-helmet";
import { useContext, useState, useEffect } from "react";
import useAPI from "../../hooks/useAPI";
import { NavLink } from "react-router-dom";
import { ContextualMenu } from "../contextualMenu";
import useIsMobile from "../../hooks/useIsMobile";

const SideBar = ({ children }) => {
  const { me } = useContext(UserContext);
  const [result] = useAPI({ me, path: "v1/credit_offer/" });
  const isMobile = useIsMobile();
  const [isOpen, setIsOpen] = useState(false);

  // Close sidebar when clicking outside on mobile
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isMobile &&
        isOpen &&
        !event.target.closest("#Main") &&
        !event.target.closest("#mobileMenuButton")
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMobile, isOpen]);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  // Mobile menu button that always stays in view
  const MobileMenuButton = () => (
    <button
      id="mobileMenuButton"
      onClick={toggleSidebar}
      className="fixed top-4 left-4 z-30 w-10 h-10"
    >
      <Icon
        icon={isOpen ? "mdi:close" : "material-symbols:menu"}
        width={24}
        className="text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
      />
    </button>
  );

  const NavItem = ({ to, icon, label }) => (
    <NavLink
      to={to}
      exact
      onClick={isMobile ? () => setIsOpen(false) : undefined}
    >
      <div
        className={`flex ${isMobile ? "flex-row" : "flex-col"} items-center ${
          isMobile ? "gap-3" : "gap-1"
        } ${isMobile ? "py-2" : "py-1"}`}
      >
        <div className="dashboard-icon-container">
          <Icon
            className="dashboard-icon"
            icon={icon}
            width={isMobile ? 24 : 20}
          />
        </div>
        {(!isMobile || (isMobile && isOpen)) && (
          <p
            className={`text-white text-center ${
              isMobile ? "text-sm" : "text-xs"
            }`}
          >
            {label}
          </p>
        )}
      </div>
    </NavLink>
  );

  return (
    <div>
      <div className="w-screen h-screen h-full">
        <Helmet>
          <title>Dashboard</title>
        </Helmet>

        {/* Mobile menu toggle button */}
        {isMobile && <MobileMenuButton />}

        {/* Overlay when mobile sidebar is open */}
        {isMobile && isOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-10"
            onClick={() => setIsOpen(false)}
          />
        )}

        <div>
          {/* Main sidebar */}
          <div
            id="Main"
            className={`fixed flex h-screen bg-float-purple-1 flex-col justify-between p-5 z-20 transition-all duration-300 ${
              isMobile
                ? isOpen
                  ? "left-0 w-[230px]"
                  : "left-[-280px] w-[230px]"
                : "left-0 w-[90px]"
            }`}
          >
            <div className="mt-7 flex flex-col w-full space-y-8">
              <div className="flex justify-center">
                <NavLink to="/dashboard" exact>
                  <LogoSmall color="white" width="26" height="48" />
                </NavLink>
              </div>

              {result.length > 0 && (
                <div className="flex flex-col space-y-4">
                  <NavItem
                    to="/dashboard"
                    icon="carbon:dashboard"
                    label="Dashboard"
                  />
                  <NavItem to="/loan" icon="carbon:meter-alt" label="Loan" />
                </div>
              )}
            </div>

            <div className="mt-6 mb-6 flex flex-col w-full space-y-4">
              {result?.length <= 0 ? (
                <NavItem
                  to="/dashboard"
                  icon="ic:outline-cloud-done"
                  label="Connect"
                />
              ) : (
                <>
                  <NavItem
                    to="/connect"
                    icon="ic:outline-cloud-done"
                    label="Connect"
                  />
                  <NavItem
                    to="/settings"
                    icon="mdi:settings-outline"
                    label="Settings"
                  />
                </>
              )}
              <NavItem
                to="/users"
                icon="mdi:user-check-outline"
                label="Users"
              />
              <NavItem to="/faq" icon="mdi:question-box-outline" label="FAQ" />
            </div>
          </div>

          {me && (
            <div
              className={`fixed flex flex-row z-10 py-4 pr-8 w-full justify-end border-b bg-float-purple-6 ${
                isMobile ? "pl-16" : ""
              }`}
            >
              <ContextualMenu />
            </div>
          )}
        </div>

        <div
          className={`h-full transition-all duration-300 ${
            isMobile ? "" : "ml-[90px]"
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default SideBar;
