import { useState } from "react";
import { BankItem } from "../bankItem";

export const BankList = ({
  banks,
  country,
  setSelectedBank,
  selectedBank,
  setCountry,
}) => {
  const [filteredBanks, setFilteredBanks] = useState(banks);

  return (
    <div className="flex flex-col h-full overflow-auto max-h-[500px]">
      <div className="flex flex-row items-center w-full gap-3 md:gap-4 px-4 md:px-8 py-4 sticky top-0 bg-white z-10 border-b md:border-none">
        <div className="relative flex-grow">
          <input
            type="text"
            placeholder="Search"
            className="border rounded-lg w-full h-10 md:h-9 px-3 focus:outline-none focus:ring-1 focus:ring-float-purple-4"
            onChange={(e) => {
              const value = e.target.value;
              if (!value) {
                setFilteredBanks(banks);
                return;
              }
              const filteredBanks = banks.filter((b) =>
                b.name.toLowerCase().includes(value.toLowerCase())
              );
              setFilteredBanks(filteredBanks);
            }}
          />
        </div>
        <div className="flex justify-end">
          <img
            src={`https://flagcdn.com/w40/${country.code}.png`}
            alt={`Country flag for ${country.name}`}
            className="border p-1 cursor-pointer w-8 h-8 rounded-full"
            onClick={() => {
              setCountry(null);
            }}
          />
        </div>
      </div>
      <div className="flex flex-wrap pt-4">
        {filteredBanks?.map((b) => (
          <BankItem
            key={b.id}
            logo={b.logo}
            name={b.name}
            onClick={() => {
              setSelectedBank(b.id);
            }}
            selected={selectedBank === b.id}
          />
        ))}
      </div>
    </div>
  );
};
