import React, { useState, useContext } from "react";
import { CountryContext } from "../../hooks/useCountry";
import CountrySelector from "../../components/countrySelector";
import Automated from "./Automated";
import Loading from "../../components/loading";
import { SecurePatternLayout } from "../../templates/SecurePatternLayout";
import useQuery from "../../hooks/useQuery";

const AcceptEidInvitePage = ({ location }) => {
  const search = useQuery();
  const { loading } = useContext(CountryContext);
  const [country, setCountry] = useState({});
  const handleCountry = (e) => {
    const parsed = JSON.parse(e.target.value);
    setCountry(parsed);
  };
  const initialCountryCode = search.get("country");

  return (
    <SecurePatternLayout title="Login" imageClass="login-col">
      <div className="flex flex-col items-center text-center w-[480px] bg-white box-shadow rounded-2xl p-12 z-10">
        <h1 className="font-normal text-float-purple-1 text-3xl">
          Accept your invitation
        </h1>
        <h3 className="mt-6 mb-8 text-base text-float-grey-90">
          Welcome. Login to accept your invitation.
        </h3>
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className="w-full">
              <CountrySelector
                onChange={(e) => handleCountry(e)}
                value={country}
                setValue={setCountry}
                initialCountryCode={initialCountryCode}
                disabled={true}
              />
            </div>
            <Automated country={country} />
          </>
        )}
      </div>
    </SecurePatternLayout>
  );
};

export default AcceptEidInvitePage;
