import { Layout } from "../../templates/Layout";
import { AddUserModal } from "../../components";
import { UserTable } from "./components";
import { useState } from "react";
import { InviteTable } from "./components/InviteTable";
import { useUsers, useInvites } from "./hooks";
import Loading from "../../components/loading";
import useIsMobile from "../../hooks/useIsMobile";

const UserPage = () => {
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [tab, setTab] = useState("users");
  const { users, loading, fetchUsers } = useUsers();
  const { invites, loading: loadingInvites, fetchInvites } = useInvites();
  const isMobile = useIsMobile();
  return (
    <Layout>
      <h2 className="">Users</h2>

      <div className="relative h-full">
        <div
          className={`${
            isMobile ? "flex flex-col gap-4" : "flex flex-row"
          } mb-8`}
        >
          <p className="my-auto">
            Here you can add new users and manage existing ones in your company.
          </p>
          <button
            className={`${
              isMobile
                ? "w-full"
                : "top-[164px] right-4 sm:right-10 w-fit ml-auto"
            }`}
            onClick={() => setOpenAddUserModal(true)}
          >
            Invite user
          </button>
        </div>
        <div className="border-b w-full mb-4">
          <div className="flex gap-4 ml-2">
            <div
              className={`settings-active pb-2 cursor-pointer ${
                tab === "users" ? "active" : ""
              }`}
              onClick={() => {
                setTab("users");
              }}
            >
              <p className={`${tab === "users" ? "" : "text-float-grey-90"}`}>
                Users
              </p>
            </div>
            <div
              className={`settings-active pb-2 cursor-pointer ${
                tab === "invites" ? "active" : ""
              }`}
              onClick={() => {
                setTab("invites");
              }}
            >
              <p className={`${tab === "invites" ? "" : "text-float-grey-90"}`}>
                Invites
              </p>
            </div>
          </div>
        </div>
        {(loading || loadingInvites) && <Loading />}
        {tab === "users" && !loading && !loadingInvites && (
          <UserTable
            users={users}
            fetchUsers={fetchUsers}
            isMobile={isMobile}
          />
        )}
        {tab === "invites" && !loadingInvites && !loading && (
          <InviteTable invites={invites} isMobile={isMobile} />
        )}
      </div>
      <AddUserModal
        hide={() => setOpenAddUserModal(false)}
        visible={openAddUserModal}
        setOpenAddUserModal={setOpenAddUserModal}
        fetchInvites={fetchInvites}
        isMobile={isMobile}
      />
    </Layout>
  );
};

export default UserPage;
