import * as Yup from "yup";
import { useContext } from "react";
import { useFormik } from "formik";
import { UserContext } from "./useWhoAmI";
import { inviteUser } from "../api";
import { useState } from "react";
import { Toast } from "../components";
import { ToastType } from "../types";

export const useInviteUserForm = ({ setOpenAddUserModal, fetchInvites }) => {
  const { me } = useContext(UserContext);
  const [submitErrors, setSubmitErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: {
      email: "",
      role: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Must be a valid e-mail")
        .required("Email is required")
        .test(
          "Check for non-company e-mail",
          "Must be company e-mail",
          (email) => !/hotmail|gmail|apple|outlook/.test(email)
        ),
      role: Yup.string().required("Role is required"),
    }),

    onSubmit: async (values) => {
      setSubmitErrors([]);
      setIsLoading(true);
      try {
        const params = {
          ...values,
          company: me.selected_company.id,
        };
        await inviteUser(params);
        formik.resetForm();
        setOpenAddUserModal(false);
        Toast({
          type: ToastType.SUCCESS,
          title: "Yes, success!",
          text: `You successfully invited ${values.email}`,
        });
        fetchInvites();
      } catch (e) {
        setSubmitErrors(e.response.data);
      } finally {
        setIsLoading(false);
      }
    },
  });

  return {
    formik,
    submitErrors,
    isLoading,
  };
};
