import LoanComponent from "../../components/loan";
import { useIsMobile } from "../../hooks/useIsMobile";

const Loan = () => {
  const isMobile = useIsMobile();
  return (
    <div
      className={`flex h-screen relative bg-float-purple-6 ${
        isMobile ? "pl-8" : "pl-[140px]"
      } pt-[120px] pr-10`}
    >
      <div className="flex flex-col mx-auto gap-y-2">
        <h1 className="">Configure your loan</h1>
        <div className="flex flex-row content-center mb-4 justify-between">
          <p className="text-gray-500">
            Term, amount, timing, repayments - you choose the funding that suits
            you
          </p>
        </div>
        <LoanComponent />
      </div>
    </div>
  );
};

export default Loan;
