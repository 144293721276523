import { useState, useEffect } from "react";
import { getUsers } from "../../../../api";

export const useUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    setLoading(true);
    getUsers()
      .then((res) => {
        setUsers(res);
      })
      .finally(() => setLoading(false));
  };

  return {
    users,
    loading,
    fetchUsers,
  };
};
