import Logo from "../components/logo";
import Image from "../assets/images/secure_pattern.png";

export const SecurePatternLayout = ({ children }) => {
  return (
    <div className="w-full min-h-screen bg-float-purple-6 flex items-center justify-center px-4 py-8">
      <div className="absolute top-6 sm:top-14 left-4 sm:left-20 h-64">
        <Logo color="#450B70" height={34} />
      </div>
      {Image && (
        <img
          className="absolute bottom-0 right-0 h-[300px] md:h-[500px] w-2/5 max-w-xs md:max-w-none hidden sm:block"
          src={Image}
          alt="secure_pattern"
        />
      )}
      {children}
    </div>
  );
};
