import { useDeleteUser } from "../../hooks";
import { Avatar } from "../../../../components";
import { ManageUserModal } from "../ManageUserModal";
import { inviteNotify } from "../../../../api";
import { ToastType } from "../../../../types";
import { Toast } from "../../../../components/toast";
import { useContext } from "react";
import { UserContext } from "../../../../hooks/useWhoAmI";
import { Icon } from "@iconify/react";

export const UserRow = ({ user, refetchUsers, isMobile }) => {
  const { me } = useContext(UserContext);
  const { showDeleteUserModal, setShowDeleteUserModal, deleteUser } =
    useDeleteUser({ refetchUsers });

  const handleReinvite = (id) => {
    try {
      inviteNotify(id);
      Toast({
        type: ToastType.SUCCESS,
        title: "Success",
        text: "Invitiation successfully sent!",
      });
    } catch (e) {
      Toast({
        type: ToastType.ERROR,
        title: "Oh no, there was an error",
        text: e.message,
      });
    }
  };

  const shouldRenderDropdown = () => {
    if (user?.invitation) {
      return false;
    }
    if (me.role.role !== "admin") {
      return false;
    }
    if (user.role?.role === "admin") {
      return false;
    }
    return true;
  };

  if (isMobile) {
    return (
      <div className="border rounded-lg p-3 mb-2 shadow-sm">
        <div className="flex items-center gap-2">
          <Avatar size={14} />
          <div className="overflow-hidden flex-1">
            <p className="text-sm font-bold truncate">
              {user.first_name} {user.last_name}
            </p>
            <p className="text-xs text-float-grey-70 break-all">{user.email}</p>
          </div>
        </div>

        <div className="flex justify-between items-center mt-2 pt-2 border-t">
          <div className="flex items-center">
            <p className="text-xs font-medium text-float-grey-90 mr-1">Role:</p>
            <p className="text-xs">{user.role?.role}</p>
          </div>

          <div className="flex items-center">
            {user.invitation && (
              <button
                className="text-xs mr-2"
                onClick={() => {
                  handleReinvite(user.id);
                }}
              >
                Resend
              </button>
            )}
            {shouldRenderDropdown() && (
              <Icon
                icon="material-symbols:manage-accounts-outline"
                className="text-float-purple-3 cursor-pointer"
                width={20}
                onClick={() => {
                  setShowDeleteUserModal(true);
                }}
              />
            )}
          </div>
        </div>

        <ManageUserModal
          visible={showDeleteUserModal}
          hide={() => setShowDeleteUserModal(false)}
          onDelete={() => deleteUser(user.role.id)}
          user={user}
          refetchUsers={refetchUsers}
          isMobile={isMobile}
        />
      </div>
    );
  }

  return (
    <div className="border-t grid grid-cols-[minmax(220px,_1fr)_minmax(250px,_1fr)_minmax(150px,_0.3fr)_minmax(80px,_1fr)] py-4">
      <div className="flex items-center gap-4">
        <Avatar size={16} />
        <p className="text-sm font-bold">
          {user.first_name} {user.last_name}
        </p>
      </div>
      <div className="flex items-center">
        <p className="text-sm">{user.email}</p>
      </div>
      <div className="flex items-center w-24">
        <p className="text-sm">{user.role?.role}</p>
      </div>
      <div className="flex items-center justify-end">
        {user.invitation && (
          <button
            className="w-fit text-xs whitespace-nowrap cursor-pointer"
            onClick={() => {
              handleReinvite(user.id);
            }}
          >
            Resend invitation
          </button>
        )}
        {shouldRenderDropdown() && (
          <Icon
            icon="material-symbols:manage-accounts-outline"
            className="text-float-purple-3 cursor-pointer mr-4"
            width={30}
            onClick={() => {
              setShowDeleteUserModal(true);
            }}
          />
        )}
      </div>
      <ManageUserModal
        visible={showDeleteUserModal}
        hide={() => setShowDeleteUserModal(false)}
        onDelete={() => deleteUser(user.role.id)}
        user={user}
        refetchUsers={refetchUsers}
        isMobile={false}
      />
    </div>
  );
};
