import { useContext } from "react";
import Pattern from "../../../../assets/images/pattern.png";
import SecurePattern from "../../../../assets/images/secure_pattern_line.svg";
import TwoColContent from "../../../../templates/twoColContent";
import { SideBar } from "../SideBar";
import { ConfigContext } from "../../../../hooks/useConfig";
import useIsMobile from "../../../../hooks/useIsMobile";

const RightContent = ({ history, location }) => {
  const { config } = useContext(ConfigContext);
  const ARR = location.state.ARR;
  const offer = Math.round(ARR * 0.7);
  const creaditOffer = offer.toLocaleString("en").replaceAll(",", " ");
  const isBigNumber = creaditOffer.length >= 8;
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <div className="w-full min-h-screen bg-float-purple-7.5 flex flex-col px-4 py-8">
        <div className="flex flex-col items-center text-center mb-6">
          <h2 className="text-float-purple-2 text-xl font-bold mb-4">
            Companies in your size are receiving credit offers up to:
          </h2>

          <div className="bg-white rounded-2xl shadow-lg py-6 px-8 w-full relative mb-6">
            <h2 className="text-float-purple-2 text-4xl font-medium">
              {creaditOffer} €
            </h2>
            <div
              className="w-full h-[48px] absolute left-0 bottom-0 rounded-b-2xl bg-repeat-y opacity-20"
              style={{
                background: `url(${SecurePattern})`,
              }}
            />
          </div>

          <p className="text-float-purple-1 text-sm mb-8">
            The amount is based on your provided information. By connecting your
            financial data in the next step you will get a precise and personal
            credit offer within a couple of days.
          </p>
        </div>

        <div className="w-full flex flex-col gap-4 mt-auto">
          <button
            className="w-full py-3.5 bg-float-purple-2 text-white font-medium rounded-lg"
            onClick={() => {
              history.replace("/dashboard");
            }}
          >
            Let's get started
          </button>
          <button
            className="w-full py-3.5 border border-float-purple-2 text-float-purple-2 font-medium rounded-lg bg-transparent"
            onClick={() => window.open(config.schedule_meeting_link)}
          >
            Book a call
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-float-purple-7.5 w-full h-full flex items-center justify-center px-6 relative">
      <img
        src={Pattern}
        alt="pattern"
        className="absolute object-contain opacity-50 mb-60"
      />
      <div
        className={`${
          isBigNumber ? "w-[520px]" : "w-[400px]"
        } flex flex-col gap-6 items-center z-10`}
      >
        <h2 className="text-float-purple-2 text-center">
          Companies in your size are receiving credit offers up to:
        </h2>
        <div className="bg-white rounded-2xl box-shadow py-8 px-12 h-[128px] relative w-full text-center">
          <h2 className="text-float-purple-2 text-6xl font-medium">
            {creaditOffer} €
          </h2>
          <div
            className="w-full h-[64px] absolute left-0 bottom-0 rounded-b-2xl bg-repeat-y opacity-20"
            style={{
              background: `url(${SecurePattern})`,
            }}
          />
        </div>
        <p className="text-float-purple-1">
          The amount is based on your provided information. By connecting your
          financial data in the next step you will get a precise and personal
          credit offer within a couple of days.
        </p>
        <div className="w-full flex flex-col gap-4">
          <button
            className="w-full m-2"
            onClick={() => {
              history.replace("/dashboard");
            }}
          >
            Let's get started
          </button>
          <button
            className="secondary w-full"
            onClick={() => window.open(config.schedule_meeting_link)}
          >
            Book a call
          </button>
        </div>
      </div>
    </div>
  );
};

const AccountCreated = ({ history, location }) => {
  const isMobile = useIsMobile();

  // For mobile, use the RightConten directly instead of the TwoColContent
  if (isMobile) {
    return <RightContent history={history} location={location} />;
  }

  return (
    <TwoColContent
      leftContent={<SideBar />}
      rightContent={<RightContent history={history} location={location} />}
    />
  );
};

export default AccountCreated;
