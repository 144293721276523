import React from "react";
import Info from "../info";

export const Dropdown = ({
  id,
  label,
  placeholder,
  className,
  required = false,
  onChange,
  value,
  error,
  disabled,
  options,
  info,
  multiple = false,
}) => {
  let inputClassName =
    "focus:ring-float-purple-3 focus:ring-1 focus:border-float-purple-4 block w-full pl-2 pr-2 sm:text-sm border-gray-300 rounded h-10 disabled:text-black disabled:bg-zinc-100";
  if (error) {
    inputClassName += " border-red-300 focus:ring-red-300 focus:border-red-600";
  }
  if (multiple) {
    inputClassName += " h-[120px]";
  }
  return (
    <div className={className}>
      <label htmlFor={id} className="text-sm font-medium text-gray-700 flex">
        {label} {required && <span className="text-float-red-1">*</span>}
        {info && <Info value={info} />}
      </label>
      <div className="mt-1 relative">
        <select
          name={id}
          id={id}
          className={inputClassName}
          placeholder={placeholder}
          required={required}
          onChange={onChange}
          value={value}
          disabled={disabled}
          multiple={multiple}
        >
          {placeholder && (
            <option value={""} disabled>
              {placeholder}
            </option>
          )}
          {options.map((o) => (
            <option key={o.value} value={o.value}>
              {o?.label || o.value}
            </option>
          ))}
        </select>
        {error && <p className="text-xs text-float-red-1">{error}</p>}
      </div>
    </div>
  );
};
