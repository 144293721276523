import { useState, useEffect } from "react";
import { getInvites } from "../../../../api";

export const useInvites = () => {
  const [invites, setInvites] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchInvites();
  }, []);

  const fetchInvites = () => {
    setLoading(true);
    getInvites()
      .then((res) => {
        setInvites(res);
      })
      .finally(() => setLoading(false));
  };

  return {
    invites,
    loading,
    fetchInvites,
  };
};
