import { doGet, doPatch, doPost, doPut } from "../common/axiosUtil";

export const putUser = async (data) => {
  const put = await doPut("v1/user/", data);
  return put;
};

export const patchUser = async (data) => {
  const put = await doPatch("v1/user/", data);
  return put;
};

export const getCountries = async () => {
  const get = await doGet("v1/country/");
  return get;
};

export const getUsers = async () => {
  const get = await doGet("v1/users/");
  return get;
};

export const inviteUser = async (data) => {
  const post = await doPost("v1/invite/", data);

  return post;
};

export const getCompanyLookup = async (country) => {
  const get = await doGet(`v1/company/look_up/?country=${country}`);

  return get;
};

export const inviteSignatory = async (data) => {
  const post = await doPost("v1/user/notify/signatory/", data);

  return post;
};

export const inviteNotify = async (invite_id) => {
  const post = await doPost(`v1/invite/${invite_id}/resend/`);
  return post;
};

export const getInvites = async () => {
  const get = await doGet("v1/invite/?status=pending");
  return get;
};
