import { Icon } from "@iconify/react";

export const TextArea = ({
  id,
  label,
  placeholder,
  value,
  onChange,
  error,
  required = false,
}) => {
  return (
    <div className="relative flex flex-col">
      <label htmlFor={id} className="text-sm font-medium text-gray-700">
        {label}
      </label>
      <textarea
        id={id}
        required={required}
        className={`
          w-full max-h-48
          rounded border-float-grey-15 text-sm p-4 text-black
          focus:border-float-purple-2 focus:outline-none focus:shadow-transparent focus:ring-offset-0 focus:ring-transparent
          ${error && "border-float-red-1 pr-10"}
        `}
        type="textarea"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      {error && (
        <>
          <Icon
            icon="material-symbols:error-rounded"
            width={16}
            className={`text-float-red-1 absolute ${
              label ? "top-8" : "top-4"
            } right-4`}
          />
          <p className="text-xs text-float-red-1">{error}</p>
        </>
      )}
    </div>
  );
};
