import React, { useState, useContext, useEffect } from "react";
import { CountryContext } from "../../hooks/useCountry";
import CountrySelector from "../../components/countrySelector";
import Automated from "./Automated";
import EmailLogin from "./EmailLogin";
import GoogleSSOLogin from "./GoogleSSOLogin";
import { Link, useHistory } from "react-router-dom";
import Loading from "../../components/loading";
import { Toast } from "../../components";
import { ToastType } from "../../types";
import { SecurePatternLayout } from "../../templates/SecurePatternLayout";

const LoginPage = ({ location }) => {
  const history = useHistory();
  const { loading } = useContext(CountryContext);
  const [country, setCountry] = useState({});
  const handleCountry = (e) => {
    const parsed = JSON.parse(e.target.value);
    setCountry(parsed);
  };

  useEffect(() => {
    if (location?.state?.resetPassword) {
      Toast({
        type: ToastType.INFO,
        title: "Password reset link sent",
        text: `We sent an email to ${location.state.resetPassword} with a link to reset your password`,
      });
      history.replace();
    }
    if (location?.state?.changedPassword) {
      Toast({
        type: ToastType.SUCCESS,
        title: "You have successfully changed your password!",
      });
      history.replace();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SecurePatternLayout title="Login" imageClass="login-col">
      <div className="flex flex-col items-center text-center w-full sm:w-[480px] bg-white box-shadow rounded-2xl p-6 sm:p-12 z-10 max-w-[95%]">
        <h1 className="font-normal text-float-purple-1 text-2xl sm:text-3xl">
          Login to your account
        </h1>
        <h3 className="mt-4 sm:mt-6 mb-6 sm:mb-8 text-sm sm:text-base text-float-grey-90">
          Welcome back. Please enter your details.
        </h3>
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className="w-full">
              <CountrySelector
                onChange={(e) => handleCountry(e)}
                value={country}
                setValue={setCountry}
              />
            </div>
            {country && country.login_verified && (
              <Automated country={country} />
            )}
            {country && country.login_email && <EmailLogin />}
            {country && country.login_sso_google && <GoogleSSOLogin />}
          </>
        )}
        <div className="mt-6 sm:mt-8">
          <span>
            <p className="inline text-float-grey-70 text-sm sm:text-base">
              Don't have an account?
            </p>
            <Link
              to={"/signup"}
              className="inline text-float-purple-2 text-sm sm:text-base"
            >
              {" "}
              Create your account
            </Link>
          </span>
        </div>
      </div>
    </SecurePatternLayout>
  );
};

export default LoginPage;
