import { UserRow } from "../UserRow";

export const UserTable = ({ users, fetchUsers, isMobile }) => {
  if (isMobile) {
    return (
      <div className="flex flex-col border-b">
        {users.map((user) => (
          <UserRow
            key={user.email}
            user={user}
            refetchUsers={fetchUsers}
            isMobile={isMobile}
          />
        ))}
      </div>
    );
  }

  return (
    <div className={`flex flex-col border-b`}>
      <div className="border-b grid grid-cols-[minmax(220px,_1fr)_minmax(250px,_1fr)_minmax(150px,_0.3fr)_minmax(80px,_1fr)] py-4 text-float-grey-1 font-bold text-sm">
        <div className="flex items-center gap-4">
          <p className="text-sm">Name</p>
        </div>
        <div className="flex items-center">
          <p className="text-sm">Email</p>
        </div>
        <div className="flex items-center">
          <p className="text-sm">Role</p>
        </div>
      </div>
      {users.map((user) => (
        <UserRow key={user.email} user={user} refetchUsers={fetchUsers} />
      ))}
    </div>
  );
};
