import { useHistory } from "react-router";
import { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../../components/input";
import Loading from "../../components/loading";
import { login } from "../../api/auth";
import { Link } from "react-router-dom";
import { useSuccessLogin } from "../../hooks/useSuccessLogin";
import { ShowCompanyModalContext } from "../../hooks/useShowCompanyModal";
import { LoginRedirect } from "./loginRedirect";
import { UserContext } from "../../hooks/useWhoAmI";
import { Button } from "../../components";

const EmailLogin = () => {
  const [done, setDone] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();
  const { setShowCompanyModal } = useContext(ShowCompanyModalContext);
  const { setCookiesAndLogin } = useSuccessLogin();
  const { login: contextLogin } = useContext(UserContext);

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values) => {
      setError("");
      try {
        const code = await login(values);
        contextLogin(code);
        setDone(true);
      } catch (err) {
        setError("No active account found with the given credentials");
      }
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Email must be a valid email")
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
  });
  const doAction = (e) => formik.handleSubmit(e);

  useEffect(() => {
    if (done) {
      LoginRedirect(history, setCookiesAndLogin, setShowCompanyModal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [done, history]);

  return (
    <>
      {formik.isSubmitting && <Loading />}
      <form className="w-full text-left mt-3 sm:mt-4">
        <Input
          required
          type="text"
          id="email"
          placeholder="E-mail"
          label="E-mail"
          className={`w-full`}
          onChange={formik.handleChange}
          value={formik.values.email}
          error={formik.errors.email || error}
        />
        <Input
          required
          type="password"
          id="password"
          placeholder="Password"
          label="Password"
          className={`mt-2 w-full`}
          onChange={formik.handleChange}
          value={formik.values.password}
          error={formik.errors.password}
        />
        <Button className="mt-6 w-full" type="submit" onClick={doAction}>
          Sign in
        </Button>
      </form>
      <div className="mt-2 sm:mt-3">
        <Link
          className="font-normal text-sm text-float-purple-2"
          to={"/reset-password"}
        >
          Forgot password?
        </Link>
      </div>
    </>
  );
};

export default EmailLogin;
