import { Icon } from "@iconify/react";
import { useState } from "react";
import useIsMobile from "../../../../hooks/useIsMobile";

const Info = ({ icon, title, text, isMobile }) => {
  const [expanded, setExpanded] = useState(false);

  if (isMobile) {
    return (
      <div
        className="flex flex-col items-center relative"
        onClick={() => setExpanded(!expanded)}
      >
        <div className="flex flex-col items-center p-2 rounded-full">
          {icon}
          <span className="text-xs text-white font-medium mt-1 text-center">
            {title}
          </span>
        </div>
        {expanded && (
          <div
            className="fixed inset-0 bg-float-black-1 bg-opacity-50 flex items-center justify-center z-50"
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className="bg-white m-4 p-5 rounded-lg shadow-lg max-w-xs w-full"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex justify-between items-center mb-3">
                <h3 className="font-bold">{title}</h3>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setExpanded(false);
                  }}
                  className="text-float-grey-70"
                >
                  <Icon icon="mdi:close" width={20} />
                </button>
              </div>
              <p className="text-sm">{text}</p>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-row gap-4">
      <div>{icon}</div>
      <div className="flex flex-col gap-1 text-float-grey-100 max-w-[280px]">
        <h3 className="text-sm lg:text-base font-bold leading-5">{title}</h3>
        <p className="text-sm leading-5">{text}</p>
      </div>
    </div>
  );
};

export const SideBar = () => {
  const isMobile = useIsMobile();
  // const [activeInfo, setActiveInfo] = useState(null);

  const secureIcon = (
    <div className="bg-float-purple-5 h-[40px] rounded-full flex items-center justify-center w-[40px]">
      <Icon
        icon="mdi:shield-check-outline"
        width={22}
        className="text-float-purple-2"
      />
    </div>
  );

  const euIcon = (
    <div className="bg-float-purple-5 h-[40px] rounded-full flex items-center justify-center w-[40px]">
      <Icon
        icon="game-icons:european-flag"
        width={32}
        className="text-float-purple-2"
      />
    </div>
  );

  const swedenIcon = (
    <div className="bg-float-purple-5 h-[40px] rounded-full flex items-center justify-center w-[40px]">
      <Icon
        icon="emojione:flag-for-sweden"
        width={28}
        className="text-float-purple-2"
      />
    </div>
  );

  if (isMobile) {
    return (
      <div className="flex justify-around w-full">
        {/* <Info
          icon={secureIcon}
          title="Secure"
          text="We only use your information to assess your credit rating. All data you share is protected by our strict non-disclosure policy."
          isMobile={isMobile}
        />
        <Info
          icon={euIcon}
          title="EU Servers"
          text="Your connected data is safely stored on servers in Sweden and in Germany."
          isMobile={isMobile}
        />
        <Info
          icon={swedenIcon}
          title="Swedish"
          text="Member of Swedish Fintech Assoc. and registered with the Swedish Financial Supervisory Authority."
          isMobile={isMobile}
        /> */}
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-8 pb-20">
      <Info
        icon={secureIcon}
        title="We keep your data secure"
        text="We only use your information to assess your credit rating. All data you share is protected by our strict non-disclosure policy."
        isMobile={isMobile}
      />
      <Info
        icon={euIcon}
        title="Servers in the EU"
        text="Your connected data is safely stored on servers in Sweden and in Germany."
        isMobile={isMobile}
      />
      <Info
        icon={swedenIcon}
        title="Made in Sweden"
        text="Member of Swedish Fintech Assoc. and registered with the Swedish Financial Supervisory Authority."
        isMobile={isMobile}
      />
    </div>
  );
};
