import { useState } from "react";
import { useHistory } from "react-router-dom";
import { SideBar } from "../components";
import TwoColContent from "../../../templates/twoColContent";
import { YourDetails, CompanyDetails } from "./components";
import useQuery from "../../../hooks/useQuery";
import useIsMobile from "../../../hooks/useIsMobile";
const AutomatedSignUp = () => {
  const query = useQuery();
  const step = query.get("current_step");
  const [currentStep, setCurrentStep] = useState(step || 0);
  const history = useHistory();
  const isMobile = useIsMobile();

  const steps = [
    {
      step: 0,
      component: YourDetails,
    },
    {
      step: 1,
      component: CompanyDetails,
    },
  ];

  const RenderedComponent = steps[currentStep]?.component;
  const nextPage = () => setCurrentStep(currentStep + 1);
  const prevPage = () => setCurrentStep(currentStep - 1);

  // Mobile view includes a progress indicator at the top
  const MobileProgressIndicator = () => (
    <div className="bg-float-purple-6 p-4">
      <div className="flex items-center justify-between">
        <div className="flex-1">
          <div className="h-2 rounded-full bg-float-grey-25 overflow-hidden">
            <div
              className="h-full bg-float-purple-2 rounded-full transition-all duration-300 ease-in-out"
              style={{ width: `${(currentStep + 1) * 50}%` }} // 50% per step
            />
          </div>
        </div>
      </div>
    </div>
  );

  // Wrap components to inject isMobile prop
  const MobileAwareComponent = (props) => (
    <RenderedComponent {...props} isMobile={isMobile} />
  );

  return (
    <>
      {isMobile && <MobileProgressIndicator />}
      <TwoColContent
        leftContent={<SideBar />}
        rightContent={
          <MobileAwareComponent
            nextPage={nextPage}
            prevPage={prevPage}
            history={history}
          />
        }
      />
    </>
  );
};

export default AutomatedSignUp;
