import { useState } from "react";
import { Link } from "react-router-dom";
import Image from "../../assets/images/secure_pattern.png";
import CountrySelector from "../../components/countrySelector";
import Logo from "../../components/logo";
import TwoColWithImage from "../../templates/twoColWithImage";
import {
  HowItWorks,
  SignUpBankID,
  SignUpEmail,
  SignUpGoogleSSO,
} from "./components";
import useIsMobile from "../../hooks/useIsMobile";

const SignUp = () => {
  const [country, setCountry] = useState({});
  const isMobile = useIsMobile();

  const handleCountry = (e) => {
    const parsed = JSON.parse(e.taret.value);
    setCountry(parsed);
  };

  // Mobile-specific signup content
  const MobileSignUp = () => (
    <div className="min-h-screen bg-float-purple-5 p-4 flex flex-col">
      <div className="p-4 mb-4">
        <Logo color="#450b70" />
      </div>

      <div className="bg-white rounded-2xl p-6 flex flex-col items-center w-full z-10 shadow-lg">
        <h2 className="text-float-purple-1 text-xl font-bold mb-2">
          Sign up and get an offer
        </h2>
        <p className="text-float-grey-90 text-sm mb-4 text-center">
          It's easy and free to get an accurate credit offer from us, just start
          by entering your details.
        </p>

        <div className="w-full mb-4">
          <CountrySelector
            onChange={(e) => handleCountry(e)}
            value={country}
            setValue={setCountry}
          />
        </div>

        <div className="w-full flex flex-col items-center gap-2 mb-4">
          {country && country.login_verified && (
            <SignUpBankID country={country} />
          )}
          {country && country.login_email && <SignUpEmail />}
          {country && country.login_sso_google && <SignUpGoogleSSO />}
        </div>

        <div className="flex gap-2 mt-2 text-sm">
          <p className="text-float-grey-70">Already have an account?</p>
          <Link className="font-normal text-float-purple-2" to={"/login"}>
            Sign in
          </Link>
        </div>
      </div>
    </div>
  );

  return isMobile ? (
    <MobileSignUp />
  ) : (
    <TwoColWithImage leftContent={<HowItWorks />} image={<Image />}>
      <div className="drop-shadow-large bg-white rounded-2xl p-12 flex flex-col items-center max-w-[600px] z-10 gap-4 ">
        <h2 className="text-float-purple-1">Sign up and get an offer</h2>
        <p className="text-float-grey-90 mb-4 mt-2">
          It's easy and free to get an accurate credit offer from us, just start
          by entering your details.
        </p>
        <div className="w-full">
          <CountrySelector
            onChange={(e) => handleCountry(e)}
            value={country}
            setValue={setCountry}
          />
        </div>
        <div className="w-full flex flex-col items-center gap-2 mb-7">
          {country && country.login_verified && (
            <SignUpBankID country={country} />
          )}
          {country && country.login_email && <SignUpEmail />}
          {country && country.login_sso_google && <SignUpGoogleSSO />}
        </div>
        <div className="flex gap-2">
          <p className="text-float-grey-70">Already have an account?</p>
          <Link className="font-normal text-float-purple-2" to={"/login"}>
            Sign in
          </Link>
        </div>
      </div>
    </TwoColWithImage>
  );
};

export default SignUp;
