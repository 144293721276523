import { useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Icon } from "@iconify/react";

export const FileUploaderStateless = ({
  files,
  setFile,
  acceptedFileTypes = [".xls", ".xlsx", ".csv"],
  error,
  setFileUploadError,
}) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      setFile([...files, ...acceptedFiles]);
    },
    [files, setFile]
  );

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    minSize: 1,
  });

  useEffect(() => {
    if (fileRejections?.[0]?.errors?.[0]?.message) {
      setFileUploadError(fileRejections[0].errors[0].message);
    }
    setFileUploadError("");

    return () => setFileUploadError("");
  }, [fileRejections, setFileUploadError]);

  const wrapperClass = () => {
    if (files.length === 0) return "my-auto";

    return "";
  };

  return (
    <>
      <div
        className={`container m-auto flex flex-col rounded-2xl outline-1 ${
          error ? "outline-float-red-1" : "outline-float-purple-15"
        } outline-dashed h-52 overflow-auto bg-float-purple-6`}
      >
        <div
          {...getRootProps({
            className: `dropzone flex flex-col`,
          })}
        >
          <div className={`flex flex-col ${wrapperClass()}`}>
            <input {...getInputProps()} />
            <div className="flex flex-col gap-6 items-center py-10">
              <Icon icon="carbon:document-add" width={50} color="#DDCDF1" />
              <span className="flex flex-col w-full">
                <p className="m-auto">
                  Drag and drop or{" "}
                  <b className="text-float-purple-2">browse files</b> to upload
                </p>
                <p className="m-auto text-sm text-float-grey-90">
                  Accepted types: {acceptedFileTypes.join(", ")}
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>
      {error && <p className="text-xs text-float-red-1">{error}</p>}
    </>
  );
};
