import { doDelete, doGet, doPatch, doPost, doPut } from "../common/axiosUtil";

export const getCreditOffer = async () => {
  const get = await doGet("v1/credit_offer/");

  return get;
};

export const getCompanyDetails = async (orgNr, country) => {
  const get = await doGet(`v1/company/${orgNr}/`, { country });
  return get;
};

export const registerCompany = async (data) => {
  const post = await doPost(`v1/company/`, data);
  return post;
};

export const connectBankLater = async (bank_connect_later) => {
  const put = await doPut(`v1/company/`, { bank_connect_later });
  return put;
};

export const getAccountingFiles = async () => {
  const get = await doGet("v1/accounting/file/");
  return get;
};

export const getAccountingConnections = async () => {
  const get = await doGet("v1/accounting/connection/");
  return get;
};

export const deleteAccountFile = async (id) => {
  const removed = await doDelete(`v1/accounting/file`, id);
  return removed;
};

export const getSubscriptionFiles = async () => {
  const get = await doGet("v1/subscription/file/");
  return get;
};

export const deleteSubscriptionFile = async (id) => {
  const removed = await doDelete(`v1/subscription/file`, id);
  return removed;
};

export const getCommentAccounting = async () => {
  const comments = await doGet("v1/accounting/comment/");

  return comments;
};

export const postCommentAccounting = async (data) => {
  const post = await doPost("v1/accounting/comment/", data);

  return post;
};

export const postCompanyAccounting = async (data) => {
  const post = await doPost("v1/accounting/file/", data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return post;
};

export const postCompanySubscription = async (data) => {
  const post = await doPost("v1/subscription/file/", data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return post;
};

export const getCommentSubscription = async () => {
  const comments = await doGet("v1/subscription/comment/");

  return comments;
};

export const postCommentSubscription = async (data) => {
  const post = await doPost("v1/subscription/comment/", data);

  return post;
};

export const postCompanyTransaction = async (data) => {
  const post = await doPost("v1/ais/file/", data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return post;
};

export const postAdditionalQuestions = async (data) => {
  const post = await doPost("v1/additional_questions/", data);

  return post;
};

export const postFeedback = async (data) => {
  const post = await doPost("v1/feedback/", data);

  return post;
};

export const postIntegrationFeedback = async (data) => {
  const post = await doPost("v1/feedback/integration/", data);

  return post;
};

export const patchIntegrationFeedback = async (id, data) => {
  const patch = await doPatch(`v1/feedback/integration/${id}/`, data);

  return patch;
};

export const patchUserRole = async (id, role) => {
  const patch = await doPatch(`v1/company_role/${id}/`, { role });

  return patch;
};

export const deleteUserRole = async (id) => {
  const removed = await doDelete(`v1/company_role`, id);

  return removed;
};
