import { useContext } from "react";
import { Button, Dropdown } from "../../../../../components";
import { useHistory } from "react-router-dom";
import { useCompanyDetailsForm } from "./hooks";
import { BUSINESS_MODEL } from "../../../../../types";
import Input from "../../../../../components/input";
import { Redirect } from "react-router-dom";
import { saveCompanyDetails } from "../../../../../common/localstorage";
import { UserContext } from "../../../../../hooks/useWhoAmI";
import {
  MobileFormHeader,
  MobileFormButtons,
  getMobileFormStyles,
} from "../../../components";

export const CompanyDetails = ({ prevPage, isMobile }) => {
  const history = useHistory();
  const { formik, loadingSubmit, done, ARR } = useCompanyDetailsForm();
  const { updateWhoAmI } = useContext(UserContext);

  // Get mobile-specific styles
  const mobileStyles = getMobileFormStyles(isMobile);

  const goBack = () => {
    saveCompanyDetails(formik.values);
    const location = {
      pathname: "/signup/manual",
      state: {
        isActiveUser: true,
      },
    };
    history.push(location);
    prevPage();
  };

  if (done) {
    updateWhoAmI();
    return (
      <Redirect
        to={{
          pathname: "/signup/account-created",
          state: { ARR },
        }}
      />
    );
  }

  return (
    <div
      className={
        isMobile
          ? mobileStyles.container
          : "flex flex-col gap-2 mt-24 mx-auto w-[560px] px-6"
      }
    >
      {/* Mobile-specific header */}
      <MobileFormHeader
        title="Company details"
        subtitle="Please provide your company information"
        isMobile={isMobile}
      />

      {/* Desktop header */}
      {!isMobile && (
        <>
          <h2 className="text-float-grey-100">Company details</h2>
          <div>
            <p className="italic text-sm text-float-grey-70">
              All fields are required
            </p>
          </div>
        </>
      )}

      <form
        className={
          isMobile ? mobileStyles.form : "mt-6 w-full flex flex-col gap-3"
        }
        onSubmit={formik.handleSubmit}
      >
        <Input
          label="Company name "
          type="text"
          id="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.errors.name}
          placeholder="Enter company name"
          className={isMobile ? "w-full" : ""}
        />
        <Input
          label="Organisation number"
          type="text"
          id="organisation_number"
          value={formik.values.organisation_number}
          onChange={formik.handleChange}
          error={formik.errors.organisation_number}
          placeholder="Enter organisation number"
          className={isMobile ? "w-full" : ""}
        />
        <Dropdown
          label="Business model"
          id="business_model"
          value={formik.values.business_model}
          onChange={formik.handleChange}
          error={formik.errors.business_model}
          options={BUSINESS_MODEL}
          placeholder="Choose business model"
          className={isMobile ? "w-full" : ""}
        />
        <span
          className={
            isMobile
              ? "w-full h-px bg-float-grey-10 my-4"
              : "bg-float-grey-10 w-3/4 h-px my-3 mx-auto"
          }
        />

        <div className={`relative ${isMobile ? "w-full" : ""}`}>
          <Input
            type="number"
            label="What is your latest ARR in EUR?"
            id="annual_recurring_revenue"
            value={formik.values.annual_recurring_revenue}
            onChange={formik.handleChange}
            error={formik.errors.annual_recurring_revenue}
            info="Annual recurring revenue, measures company's growth and revenue stream."
            className={isMobile ? "w-full pr-6" : ""}
          />
          <p
            className={`absolute ${
              isMobile ? "top-[34px] right-8" : "top-[34px] right-4"
            } text-float-grey-70 text-sm`}
          >
            €
          </p>
        </div>
        <div className={`relative ${isMobile ? "w-full" : ""}`}>
          <Input
            type="number"
            label="What's your average monthly cash burn in EUR?"
            id="average_monthly_cash_burn"
            value={formik.values.average_monthly_cash_burn}
            onChange={formik.handleChange}
            error={formik.errors.average_monthly_cash_burn}
            info="How much you are spending in cash reserves on a monthly basis including, operating expenses, marketing and sales and dept expenses."
            className={isMobile ? "w-full pr-6" : ""}
          />
          <p
            className={`absolute ${
              isMobile ? "top-[34px] right-8" : "top-[34px] right-4"
            } text-float-grey-70 text-sm`}
          >
            €
          </p>
        </div>
        <div className={`relative ${isMobile ? "w-full" : ""}`}>
          <Input
            type="number"
            label="What is your annual revenue growth?"
            id="annual_growth"
            value={formik.values.annual_growth}
            onChange={formik.handleChange}
            error={formik.errors.annual_growth}
            info="Percentage change of your growth from previous year."
            className={isMobile ? "w-full pr-6" : ""}
          />
          <p
            className={`absolute ${
              isMobile ? "top-[34px] right-8" : "top-[34px] right-4"
            } text-float-grey-70 text-sm`}
          >
            %
          </p>
        </div>

        {/* Mobile buttons */}
        {isMobile ? (
          <MobileFormButtons
            isMobile={isMobile}
            nextLabel="Create account"
            backLabel="Back"
            onBack={goBack}
          />
        ) : (
          <div className="flex gap-4 mt-10 mb-4">
            <button
              onClick={goBack}
              type="button"
              className="secondary"
              disabled={loadingSubmit}
            >
              Back
            </button>
            <Button type="submit" className="grow" loading={loadingSubmit}>
              Create account
            </Button>
          </div>
        )}
      </form>
    </div>
  );
};
