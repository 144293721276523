import * as Yup from "yup";
import { useState, useContext } from "react";
import { useFormik } from "formik";
import TwoColWithImage from "../../templates/twoColWithImage";
import Image from "../../assets/images/secure_pattern.png";
import Input from "../../components/input";
import { useGetErrorMessage } from "../../utils/";
import { useHistory } from "react-router";
import { registerUser } from "../../api/auth";
import { UserContext } from "../../hooks/useWhoAmI";
import { login } from "../../api/auth";
import { Toast } from "../../components";
import { ToastType } from "../../types";
import GoogleSSOLogin from "./GoogleSSOLogin";

const AcceptInvite = ({ location }) => {
  const search = new URLSearchParams(location.search);
  const { login: loginContext } = useContext(UserContext);
  const [submitError, setSubmitError] = useState({});
  const { errorMessages } = useGetErrorMessage();
  const history = useHistory();

  let invite_token = search.get("token");
  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setSubmitError({});
      const formData = { invite_token: invite_token, ...values };
      try {
        const userData = await registerUser(formData);

        Toast({
          type: ToastType.SUCCESS,
          title: "Yes, success!",
          text: "Invite accepted",
        });
        const code = await login({
          email: userData.email,
          password: formData.password1,
        });
        loginContext(code);
        history.push("/dashboard");
      } catch (err) {
        const error = errorMessages(err.response.data);
        setSubmitError(error);
      }
    },
    initialValues: {
      first_name: "",
      last_name: "",
      phone_number: "",
      job_title: "",
      password1: "",
      password2: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("First name is required"),
      last_name: Yup.string().required("Last name is required"),
      phone_number: Yup.number().required("Phone number is required"),
      job_title: Yup.string().required("Job title is required"),
      password1: Yup.string()
        .required("Password is required")
        .min(8, "Password is too short - should be 8 charactes minimum."),
      password2: Yup.string()
        .required("Confirmed password is required")
        .oneOf([Yup.ref("password1")], "Passwords must match."),
    }),
  });

  console.log("submitError", submitError);
  return (
    <TwoColWithImage title="Signup" image={<Image />}>
      <form onSubmit={formik.handleSubmit}>
        <h3 className="text-left mb-4 text-lg lg:text-2xl">
          Login credentials & User information
        </h3>
        <div className="flex flex-row flex-wrap w-full gap-4">
          <Input
            type="text"
            id="first_name"
            label="First name"
            className="flex-1 basis-5/12"
            required
            onChange={formik.handleChange}
            value={formik.values.firstName}
          />
          <Input
            type="text"
            id="last_name"
            label="Last name"
            className="flex-1 basis-5/12"
            required
            onChange={formik.handleChange}
            value={formik.values.lastName}
          />
          <Input
            type="tel"
            id="phone_number"
            label="Phone number"
            className="flex-1 basis-5/12"
            required
            onChange={formik.handleChange}
            value={formik.values.phoneNumber}
          />
          <Input
            type="text"
            id="job_title"
            label="Job title"
            className="flex-1 basis-5/12"
            required
            onChange={formik.handleChange}
            value={formik.values.job_title}
          />
          <Input
            type="password"
            id="password1"
            label="Password"
            className="flex-1 basis-5/12"
            required
            onChange={formik.handleChange}
            value={formik.values.password1}
            error={formik.errors.password1 || submitError.password1}
          />
          <Input
            type="password"
            name="password2"
            id="password2"
            label="Confirm password"
            className="flex-1 basis-5/12"
            value={formik.values.password2}
            onChange={formik.handleChange}
            error={formik.errors.password2 || submitError.password2}
          />
        </div>
        {submitError?.email && (
          <p className="text-float-red-1">{submitError.email}</p>
        )}
        <button
          className="mt-4 ml-auto"
          type="submit"
          //onClick={(e) => formik.handleSubmit(e)}
          disabled={formik.isSubmitting}
        >
          Continue
        </button>
      </form>
      <div className="flex-1 basis-5/12">
        <GoogleSSOLogin invite_token={invite_token} />
      </div>
    </TwoColWithImage>
  );
};

export default AcceptInvite;
