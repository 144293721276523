import { useContext } from "react";
import { Dropdown, TextArea } from "../../../../../components";
import { InfoModal } from "../../../../../components/infoModal";
import { useSubmitConnectForm } from "./hooks/useSubmitConnectForm";
import { START_TIMESPAN, CURRENCY_ARRAY, LOAN_TAKER } from "./constants";
import Input from "../../../../../components/input";
import Loading from "../../../../../components/loading";
import useAPI from "../../../../../hooks/useAPI";
import { UserContext } from "../../../../../hooks/useWhoAmI";

export const SubmitModal = ({ hide, visible }) => {
  const { me, loading: user_loading } = useContext(UserContext);
  const [result, loading, error] = useAPI({
    me,
    path: "v1/additional_questions/",
  });
  const { formik, initialObj } = useSubmitConnectForm(result);
  const currencyError = formik.errors.currency || formik.errors.amount;

  if (loading || user_loading) {
    return <Loading />;
  }

  if (error) {
    return <p>An error occurred, please try again later.</p>;
  }

  return (
    <InfoModal
      hide={() => {
        hide();
        formik.resetForm();
      }}
      visible={visible}
      title="Some additional questions"
      submitButton={
        <button
          form="submitConnect"
          type="submit"
          className="w-full"
          onClick={formik.handleSubmit}
          disabled={formik.isSubmitting}
        >
          {initialObj ? "Update application" : "Submit application"}
        </button>
      }
      content={
        <form id="submitConnect" className="overflow-auto max-h-[500px]">
          <div className="flex flex-col gap-4 text-float-grey-90 max-w-[514px]">
            <p>
              Before submitting your credit application, we have two more
              questions to ask in order to speed up your process.
            </p>
            <Dropdown
              id="start_timespan"
              value={formik.values.start_timespan}
              error={formik.errors.start_timespan}
              onChange={formik.handleChange}
              label="When would you like to start using the credit?"
              className="flex-1 basis-5/12"
              options={START_TIMESPAN}
              placeholder="Choose timeframe"
            />
            <div className="flex flex-row items-end gap-2">
              <Input
                className="w-3/4"
                id="amount"
                value={formik.values.amount}
                error={currencyError}
                onChange={formik.handleChange}
                label="How much funding would you like to take from Float?"
                placeholder="Amount"
                type="number"
              />
              <Dropdown
                className="w-1/4"
                id="currency"
                value={formik.values.currency}
                onChange={formik.handleChange}
                label="Currency"
                options={CURRENCY_ARRAY}
                error={formik.errors.amount && "Please enter currency"}
                placeholder="Choose"
              />
            </div>
            <div id="checkbox-group" className="flex flex-col gap-2">
              <label
                htmlFor="checkbox-group"
                className="block text-sm font-medium text-gray-700"
              >
                Who will ultimately decide about the loan?
              </label>
              {formik.errors.loan_taker && (
                <p className="text-xs text-float-red-1">
                  {formik.errors.loan_taker}
                </p>
              )}
              {LOAN_TAKER.map(({ label, value }) => (
                <Input
                  id={`loan_taker.${value}`}
                  type="checkbox"
                  onChange={({ target: { name, checked } }) => {
                    console.log("onChange", name, checked);
                    formik.setFieldValue(name, checked);
                  }}
                  value={formik.values.loan_taker[value]}
                  checked={formik.values.loan_taker[value]}
                  label={label}
                  key={value}
                />
              ))}
            </div>
            <div className="mb-14">
              <TextArea
                id="comment"
                placeholder="Write your comment here ..."
                label="Additional comments about your credit enquiry"
                value={formik.values.comment}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </form>
      }
    />
  );
};
