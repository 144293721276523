import { useState } from "react";
import { deleteUserRole } from "../../../../api";
import { ToastType } from "../../../../types";
import { Toast } from "../../../../components/toast";

export const useDeleteUser = ({ refetchUsers }) => {
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);

  const deleteUser = (role_id) => {
    console.log(role_id);
    deleteUserRole(role_id).then(() => {
      Toast({
        type: ToastType.SUCCESS,
        title: "Success",
        text: "User deleted successfully",
      });
      refetchUsers();
    });
  };

  return {
    showDeleteUserModal,
    setShowDeleteUserModal,
    deleteUser,
  };
};
