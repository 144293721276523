import { Redirect, withRouter } from "react-router";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../hooks/useWhoAmI";
import Loading from "../../components/loading";
import Overview from "../../components/overview";
import Onboard from "./onboard";
import useAPI from "../../hooks/useAPI";
import useIsMobile from "../../hooks/useIsMobile";

// Mobile warning modal component
const MobileWarningModal = ({ onDismiss }) => {
  return (
    <div className="fixed inset-0 z-50 bg-float-black-1 bg-opacity-80 animate-in fade-in flex flex-col">
      <div className="flex-1 flex flex-col justify-center mx-auto p-4 text-center">
        <div className="flex flex-col items-center max-w-[80%] bg-functional-yellow-10 p-5 rounded-lg">
          <div className="w-full max-w-[80%]">
            <h2 className="text-xl font-bold mb-4 text-center">
              Mobile Experience Limited
            </h2>
          </div>
          <div className="bg-functional-yellow-10 p-5 rounded-lg mb-6 w-full max-w-[80%]">
            <p className="text-center text-sm text-float-black-90 mb-3">
              Float is optimized for desktop. You may encounter limited
              functionality on mobile devices.
            </p>
            <p className="text-center text-xs text-float-grey-70">
              For the best experience, please visit on a desktop computer.
            </p>
          </div>
          <div className="w-full max-w-[80%]">
            <button
              onClick={onDismiss}
              className="w-full px-6 py-3 bg-float-purple-3 text-white rounded-lg hover:bg-float-purple-2 transition-colors font-medium"
            >
              Continue Anyway
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const { me, updateWhoAmI } = useContext(UserContext);
  const [result, loading] = useAPI({ me, path: "v1/credit_offer/" });
  const isMobile = useIsMobile();
  const [showMobileWarning, setShowMobileWarning] = useState(true);

  useEffect(() => {
    if (me && !me.selected_company) {
      updateWhoAmI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);

  if (loading) {
    return (
      <div className="bg-float-purple-6 w-screen min-h-screen max-h-full flex items-center justify-center">
        <Loading />
      </div>
    );
  }

  const renderContent = () => {
    if (result.length > 0) {
      return <Overview />;
    }

    if (!me.selected_company) {
      return <Redirect to="/signup" />;
    }

    return <Onboard />;
  };

  return (
    <>
      {isMobile && showMobileWarning && (
        <MobileWarningModal onDismiss={() => setShowMobileWarning(false)} />
      )}
      <div>{renderContent()}</div>
    </>
  );
};

export default withRouter(Dashboard);
